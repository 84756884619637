/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {useState} from 'react';
import {Button, Image, Modal, Row, Col} from "react-bootstrap";

import './mint.scss';

import ElfPremint from "../../assets/elf/elf-premint.png";

export const Buy = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Row className="d-flex justify-content-center">
                <Col md={6} sm={{span: 8, offset: 1}} className="d-sm-grid gap-sm-3 justify-content-center">
                    <Button disabled variant="warning" size="lg" className="d-block cta-btn text-center px-5 py-3"
                            onClick={()=> window.open("https://mint.elfnft.com/", "_blank")}>
                        Minting Halted
                    </Button>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Mint is not live yet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image fluid src={ElfPremint} alt=""/>
                    <a href="https://mint.elfnft.com/"><Button className="justify-content-center align-self-center" variant="light">Get your elf
                        now!</Button></a>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        I'll come back later
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};