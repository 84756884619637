import React from "react";
import Slider from "react-slick";
import Image from 'react-bootstrap/Image';

import Elf01 from "../../assets/elf/elf-01.jpg";
import Elf02 from "../../assets/elf/elf-02.jpg";
import Elf03 from "../../assets/elf/elf-03.jpg";
import Elf04 from "../../assets/elf/elf-04.jpg";
import Elf05 from "../../assets/elf/elf-05.jpg";

import "./gallery.scss";

export const GalleryV2 = () => {
    const settings = {
        dots: false,
        arrows:false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        pauseOnHover: true,
        autoplay: true,
        speed: 800,
        autoplaySpeed: 800,
        cssEase: "linear"
    };
    return (
        <div className="GalleryV2">
            <Slider {...settings}>
                <div>
                    <Image src={Elf01} alt="" fluid />
                </div>
                <div>
                    <Image src={Elf02} alt="" fluid />
                </div>
                <div>
                    <Image src={Elf03} alt="" fluid />
                </div>
                <div>
                    <Image src={Elf04} alt="" fluid />
                </div>
                <div>
                    <Image src={Elf05} alt="" fluid />
                </div>
            </Slider>
        </div>
    );
};