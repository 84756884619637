/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
// import {ReactComponent as ReactLogo} from '../assets/SolPhantoms-Logo.svg';
import {Container, Navbar, Nav, Image, Stack} from "react-bootstrap";
// TODO: Replace logo with the "by SolPhantoms" version
import Logo from '../assets/elf/Logo_Sol_Phantoms_Twitter-01-01.png';
import discordLogo from '../assets/icons8-discord.svg';
import twitterLogo from '../assets/icons8-twitter.svg';

export const Header = () => {
    return (
        <div>
            <Navbar expand="sm" className="d-block d-md-none">
                <Container className="justify-content-center">
                    <Navbar.Brand href="#home" className="">
                        <img src={Logo} alt="SolPhantoms" className="position-relative d-inline" width="100" />
                    </Navbar.Brand>
                </Container>
            </Navbar>
            <Navbar collapseOnSelect expand="md" bg="none" variant="dark">
                <Container>
                    <Navbar.Brand href="#home" className="d-md-block d-sm-none">
                        <img src={Logo} alt="SolPhantoms" className="position-relative d-none d-md-block" width="100" />
                    </Navbar.Brand>
                    <Stack direction="horizontal" className="ms-3 d-none d-sm-none d-md-flex" gap={3}>
                        <div className="vr me-4" />
                        <a href="https://twitter.com/ElfNFT" target="_blank" rel="external" className="border p-2 shadow-lg"><Image src={twitterLogo} width="32" /></a>
                        <a href="https://discord.gg/xzBxjf5yMS" target="_blank" rel="external" className="border p-2 shadow-lg"><Image src={discordLogo} width="32" /></a>
                    </Stack>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                        <Nav className="text-center">
                            <Nav.Link className="ms-md-3" href="#mint">Mint</Nav.Link>
                            <Nav.Link className="ms-md-3" href="#about">About</Nav.Link>
                            <Nav.Link className="ms-md-3" href="#faq">FAQ</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
};