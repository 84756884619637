import React from 'react';
import Slider from "react-slick";
import {Container} from "react-bootstrap";

import "./roadmap.scss";

const roadmapStations = [
  {
    station: 'One',
    subStation: [
        'Launching the website',
        'Launching Twitter & Discord',
        'Announcing NGO partnership with Jusoor',
        'Community building competitions and games, Twitter and Discord',
        'Announcing Whitelist form to be filled, allowing you to mint each elf at 0.5 SOL'
        ]
  },
  {
    station: 'Two',
    subStation: [
        'Launching 98% of the NFT collection to mint (2% to share with our friends on Twitter and Discord)',
        'Listing on marketplaces',
        'Community building competitions and games, Twitter and Discord'
    ]
  },
  {
    station: 'Three',
    subStation: [
        'Unwrapping the collection rarity',
        'Revealing the second part of SolPhantoms Christmas project',
        'Community building competitions and games, Twitter and Discord'
    ]
  },
  {
    station: 'Four',
    subStation: [
        'Validating wallets for Elf holders on Discord to gain access to private channels and other exciting perks that Santa will tell you about!',
        'Releasing the second part of the SolPhantoms Christmas Project: Solana Santa (can we cover it with a box to be removed when announcing it?)',
        'Community building competitions and games, Twitter and Discord'
    ]
  },
  {
    station: 'Five',
    subStation: [
        'Q and A with the community for a brainstorming session for projects they are interested in',
        'Community building competitions and games, Twitter and Discord'
    ]
  },
  {
    station: 'Six',
    subStation: [
        'Airdropping 3D Santas to:',
        [
            'Solana Santa owners who minted it during Christmas night',
            'Validated holders who have both Solana Santa & Elf NFT in their wallets'
        ]
    ]
  },
  {
    station: 'Seven',
    subStation: [
        'Revealing Q1 2022 roadmap',
        'Do we hear Secret Santa?'
    ]
  }
]

const station = roadmapStations.map((x) =>
    <div className="RoadmapSlickStation align-content-center justify-content-center">
      <h4>Station {x.station}</h4>
      <ul>
        { x.subStation.map((sub) => <li>
            { Array.isArray(sub) ? <ul> { sub.map((sub2) => <li>{sub2}</li> ) } </ul> : sub }
        </li> ) }
      </ul>
    </div>
);



export const RoadmapSlick = () => {
  const settings = {
    infinite: true,
    accessibility: true,
    pauseOnHover: true,
    rows: 1,
    slidesToShow: 2,
    slidesToScroll: 1,
    vertical: true,
    autoplay: true,
    fade: false,
    speed: 3000,
    autoplaySpeed: 6000,
    adaptiveHeight: false,
    className: "RoadmapSlickSlider",
      beforeChange: function(currentSlide: number, nextSlide: number) {
      // console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function(currentSlide: number) {
      // console.log("after change", currentSlide);
    }
  };
  return(
      <Container fluid className="rm-container py-5" style={{minHeight: '600px'}}>
        <h2 className="text-center pt-2 pb-5">Roadmap</h2>
        <Container>
          <Slider {...settings} >
              {station}
          </Slider>
        </Container>
      </Container>
  );
};