import React from 'react';

import {Team} from "./components/team/team";
import {RoadmapSlick} from "./components/roadmap/RoadmapSlick";
import { GalleryV2 } from "./components/gallery/GalleryV2";
import { Faq } from "./components/faq/faq";
import {About} from "./components/about/about";
import {Footer} from "./components/footer";

export const Home = () => {
  return (
      <>
          <About />
          <GalleryV2/>
          <Team />
          <RoadmapSlick />
          <Faq />
          <Footer />
      </>
  );
};