import React from 'react';
import "./App.css";
import "./css/style.scss";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { Header } from "./components/header";
import { Home } from "./Home";
const theme = createTheme({
    palette: {
        type: 'dark',
    },
    overrides: {
        MuiButtonBase: {
            root: {
                justifyContent: 'flex-start',
            },
        },
        MuiButton: {
            root: {
                textTransform: undefined,
                padding: '12px 16px',
            },
            startIcon: {
                marginRight: 8,
            },
            endIcon: {
                marginLeft: 8,
            },
        },
    },
});

const App = () => {
  return (
      <ThemeProvider theme={theme}>
          <Header />
          <Home />
      </ThemeProvider>
  );
};

export default App;
