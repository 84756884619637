/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Container, Row, Col, Image, Stack} from 'react-bootstrap';
import SolIcon from "../assets/sol-phantoms-animated.gif";
import NftCalendar from "../assets/black_and_white_transparent_inverted_version.svg";
import NftDroplist from "../assets/NFTDROPLIST-LOGO-248x300.png";
export const Footer = () => {
    return (
        <footer className="footer pt-5 pb-3">
            <Container>
                <Row>
                    <Col md={8}>
                        <Stack direction="horizontal" gap={3}>
                            <a href="https://www.solphantoms.com" rel="author">
                                <Image fluid width="140" src={SolIcon} alt={"SolPhantoms"}/>
                            </a>
                            <p className="small">
                                ElfNFT is SolPhantom's first collection kicking off a journey for grass-roots movement.
                                We are bold and belive in a brighter future for mankind.
                            </p>
                        </Stack>
                    </Col>
                    <Col md={{span: 2, offset: 1}}>
                        <Stack>
                            <div>As seen on</div>
                            <Stack direction="horizontal" gap={5}>
                                <a href="https://nftcalendar.io/event/elf-nft/" target="_blank" rel="noreferrer"><Image width="110" fluid src={NftCalendar} /></a>
                                <a href="https://www.nftdroplist.co.uk/" target="_blank" rel="noreferrer"><Image width="100" fluid src={NftDroplist} /></a>
                            </Stack>
                        </Stack>
                    </Col>
                </Row>
            </Container>
            <Container className="mt-3">
                <Row>
                    <Col>
                        <p>
                            Made with &hearts; by the <a href="https://www.solphantoms.com/" target="_blank" rel="author">SolPhantoms</a> team.
                        </p>
                    </Col>
                    <Col>
                        <p className="text-end">
                            <a href="#">Back to top</a>
                        </p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};